module.exports = function () {
  require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/core/core.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/validation-forms/validation-forms.custom.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/notifications/notifications.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/charts/charts.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/contacts/contacts.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/tabs-accordions/tabs-accordions.custom.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/tooltips/tooltips.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/table-responsive/table-responsive.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/table-collapsed/table-collapsed.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/list-collapsed/list-collapsed.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/modal/modal.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/navigation/navigation.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/layout/layout.js");

require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/tools/tools.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/main-slider/main-slider.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/slider/slider.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/mask/mask.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/bods/bods.js");

require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/slider-fullscreen/slider-fullscreen.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/filter/filter.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/popup/popup.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/news/news.js");


require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/news/news-filter/news-filter.js");


require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/social-networks/social-networks.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/media-library/media-library.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/metals/metals.js");

require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/page-nav/page-nav.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/search/search.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/magnify/magnify.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/glossary/glossary.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/share/share.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/print/print.js");

require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/year-content/year-content.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/structure-group/structure-group.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/strategy/strategy.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/bm/bm.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/governance-structure/governance-structure.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/stockchart/stockchart.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/products/products.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/esg/esg.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/bootstrap-tour/bootstrap-tour.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/calendar/calendar.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/cases/cases.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/mainpage/mainpage.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/business/business.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/media/media.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/structure/structure.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/video/video.js");



require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/used-products-list/used-products-list.js");
require("/pub/www/kolagmknornik/releases/20240829082640Z/frontend/components/non-core-assets/non-core-assets.js");;
};

module.exports = module.exports.apply(module, [])