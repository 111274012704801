var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "div",
        { staticClass: "illiquid" },
        [
          _c("filter-illiquid", {
            attrs: { data: _vm.filter },
            on: { change: _vm.onFilterChange }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "u-relative u-top-padding--s" },
            [
              _vm.isLoading
                ? _c("z-preloader", { staticClass: "illiquid__preloader-top" })
                : _vm._e(),
              _vm._v(" "),
              _vm.items && _vm.items.length
                ? _c("results", {
                    attrs: { items: _vm.items },
                    on: {
                      "all-selected": _vm.onAllSelected,
                      "show-selected": _vm.onShowSelected
                    }
                  })
                : _c("div", [
                    _c("h4", {
                      staticClass: "u-bottom-margin--3xs",
                      domProps: { innerHTML: _vm._s(_vm.TEXT.notFound.title) }
                    }),
                    _vm._v(" "),
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.TEXT.notFound.description)
                      }
                    })
                  ]),
              _vm._v(" "),
              _vm.isLoading ? _c("z-preloader") : _vm._e(),
              _vm._v(" "),
              _vm.paginationData
                ? _c("z-pagination", {
                    staticClass: "u-top-margin--3xl-important",
                    attrs: {
                      "show-next": "",
                      "show-prev": "",
                      data: _vm.paginationData
                    },
                    on: { "change-page": _vm.onPaginationChange }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2d6e1735", { render: render, staticRenderFns: staticRenderFns })
  }
}