<template>
  <div class="illiquid-results" v-if="items && items.length">

    <div class="illiquid-results__actions">
      <button
          class="illiquid-results__actions-button"
          @click="toggleSelectAllCheckboxes"
      >
        <svg version="1.1" viewBox="0 0 24 24" class="z-icon z-fill" style="width: 20px; height: 20px;">
          <g clip-path="url(#clip0_9226_31769)">
            <path pid="0" fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.928 2.826a9.4 9.4 0 015.898.582.6.6 0 00.488-1.096 10.6 10.6 0 106.286 9.689v-.92a.6.6 0 10-1.2 0V12A9.4 9.4 0 119.928 2.826zm12.496 1.598a.6.6 0 10-.849-.848L12 13.162l-2.576-2.576a.6.6 0 00-.848.849l3 3a.6.6 0 00.848 0l10-10.01z"
                  fill="#0077C8"></path>
          </g>
          <defs>
            <clipPath id="clip0_9226_31769">
              <path pid="1" fill="#fff" d="M0 0h24v24H0z"></path>
            </clipPath>
          </defs>
        </svg>
        {{ isAllSelected ? 'Очистить все позиции' : 'Выбрать все позиции' }}
      </button>
      <button
          class="illiquid-results__actions-button"
          @click="toggleShowSelected"
          :disabled="!checkedItems.length"
      >
        <svg version="1.1" viewBox="0 0 24 24" class="z-icon z-fill" style="width: 20px; height: 20px;">
          <g clip-path="url(#clip0_9226_31858)">
            <path pid="0" fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.238 12.925a16.92 16.92 0 01-.53-.875L1.681 12l.027-.05a19.075 19.075 0 012.604-3.54C6.138 6.466 8.74 4.6 12 4.6s5.863 1.865 7.687 3.81a19.08 19.08 0 012.604 3.54l.028.05-.028.05a19.081 19.081 0 01-2.604 3.54C17.863 17.536 15.26 19.4 12 19.4c-3.26 0-5.863-1.864-7.687-3.81a19.077 19.077 0 01-2.075-2.665zm21.298-1.193L23 12c.537.269.537.269.536.27v.002l-.004.005-.01.02-.02.038-.018.036a18.13 18.13 0 01-.714 1.205 20.28 20.28 0 01-2.207 2.835C18.637 18.464 15.74 20.6 12 20.6s-6.637-2.136-8.563-4.19a20.28 20.28 0 01-2.774-3.773 12.172 12.172 0 01-.185-.34l-.01-.02-.003-.006-.001-.002L1 12a66.968 66.968 0 01-.537-.268v-.001l.002-.002.003-.006.01-.02a7.484 7.484 0 01.185-.34A20.274 20.274 0 013.438 7.59C5.362 5.535 8.26 3.4 12 3.4c3.74 0 6.637 2.135 8.563 4.189a20.274 20.274 0 012.774 3.773 11.962 11.962 0 01.185.34l.01.02.003.006.001.002zM23 12l.537-.268a.6.6 0 010 .537L23 12zM.463 11.732L1 12l-.537.269a.6.6 0 010-.537zM9.6 12a2.4 2.4 0 114.8 0 2.4 2.4 0 01-4.8 0zM12 8.4a3.6 3.6 0 100 7.2 3.6 3.6 0 000-7.2z"
                  fill="#0077C8"></path>
          </g>
          <defs>
            <clipPath id="clip0_9226_31858">
              <path pid="1" fill="#fff" d="M0 0h24v24H0z"></path>
            </clipPath>
          </defs>
        </svg>
        {{ !showSelectedOnly ? 'Посмотреть выбранные' : 'Посмотреть все' }}
      </button>

      <download-exel-btn
          class="illiquid-results__actions-button"
          :params="{id: [...checkedItems]}"
          :disabled="!checkedItems.length"
      />

      <a class="illiquid-results__link" href="/personal-area/">
        Подать коммерческое предложение
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 9L5 5L1 1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </a>
    </div>

    <div
        class="sale-page-card"
        v-for="(item, index) in items"
        :key="index"
    >
      <div class="sale-page-card__wrapper">
        <label class="sale-page-card__checkbox">
          <input
              type="checkbox"
              :value="item.id"
              :checked="checkedItems.includes(item.id)"
              @change="setCheckboxArray"
          >
          <span class="sale-page-card__checkbox-box">
            <svg version="1.1" viewBox="0 0 24 24" class="z-icon z-fill z-top"
                 style="width: 22px; height: 22px;">
              <path pid="0" fill-rule="evenodd" clip-rule="evenodd"
                    d="M20.424 6.425a.6.6 0 10-.848-.849L9 16.152l-4.576-4.576a.6.6 0 00-.848.849l5 5a.6.6 0 00.848 0l11-11z"
                    fill="#0077C8"></path>
            </svg>
          </span>
        </label>
        <div class="sale-page-card__body">
          <span class="sale-page-card__text" v-html="item.name"/>
          <sale-page-table>

            <sale-page-table-item class="sale-page-card__code" title="Код ЕНС">
              <span v-if="item.code && item.code.value" v-html="item.code.value"/>
              <span v-else>—</span>
            </sale-page-table-item>

            <sale-page-table-item class="sale-page-card__gost" title="ГОСТ">
              <span v-if="item.gost && item.gost.value" v-html="item.gost.value"/>
              <span v-else>—</span>
            </sale-page-table-item>

            <sale-page-table-item class="sale-page-card__brand" title="Марка">
              <span v-if="item.brand && item.brand.value" v-html="item.brand.value"/>
              <span v-else>—</span>
            </sale-page-table-item>

            <sale-page-table-item class="sale-page-card__type" title="Наименование группы материалов">
              <span v-if="item.type && item.type.value" v-html="item.type.value"/>
              <span v-else>—</span>
            </sale-page-table-item>

            <sale-page-table-item class="sale-page-card__subtype" title="Наименование подгруппы материалов">
              <span v-if="item.subType && item.subType.value" v-html="item.subType.value"/>
              <span v-else>—</span>
            </sale-page-table-item>

            <sale-page-table-item class="sale-page-card__tech-props" title="Технические характеристики">
              <span v-if="item.techProps && item.techProps.value" v-html="item.techProps.value"/>
              <span v-else>—</span>
            </sale-page-table-item>

            <sale-page-table-item class="sale-page-card__tech-state" title="Тех. состояние">
              <span v-if="item.techState && item.techState.value" v-html="item.techState.value"/>
              <span v-else>—</span>
            </sale-page-table-item>

            <sale-page-table-item class="sale-page-card__amount" title="Кол-во к&nbsp;реал-ии">
              <span v-if="item.amount && item.amount.value" v-html="item.amount.value"/>
              <span v-else>—</span>
            </sale-page-table-item>
            <sale-page-table-item class="sale-page-card__amount-name" title="ЕИ кол.">
              <span v-if="item.amountName && item.amountName.value" v-html="item.amountName.value"/>
              <span v-else>—</span>
            </sale-page-table-item>
            <sale-page-table-item class="sale-page-card__price" title="Цена за&nbsp;единицу">
              <span v-if="item.price && item.price.value" v-html="Number(item.price.value).toLocaleString()"/>
              <span v-else>—</span>
            </sale-page-table-item>
            <sale-page-table-item class="sale-page-card__all-price" title="Сумма">
              <span v-if="item.priceAll && item.priceAll.value" v-html="Number(item.priceAll.value).toLocaleString()"/>
              <span v-else>—</span>
            </sale-page-table-item>
          </sale-page-table>
        </div>
        <sale-card-slider
            v-if="item.images.length"
            :images="item.images"
            :id="item.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SaleCardSlider from './SaleCardSlider.vue';
import SalePageTableItem from './SalePageTableItem.vue';
import SalePageTable from './SalePageTable.vue';
import DownloadExelBtn from './DownloadExelBtn.vue';
import ZCheckbox from '../../../app/ZCheckbox/ZCheckbox.vue'

export default {
  name: 'illiquid-results',
  props: {
    items: {
      type: Array
    }
  },

  components: {
    DownloadExelBtn,
    SaleCardSlider,
    SalePageTableItem,
    SalePageTable,
    ZCheckbox,
  },
  data() {
    return {
      checkedItems: [], // чекбокс не работает без v-model
      isAllSelected: false,
      showSelectedOnly: false,
    }
  },
  created() {
    localStorage.removeItem('saleCheckboxArray')
  },
  methods: {
    setCheckboxArray(e) {
      this.isAllSelected = false
      let previousChecked = JSON.parse(localStorage.getItem('saleCheckboxArray')) || []

      if (e.target.checked) {
        this.checkedItems.push(e.target.value)
      } else {
        previousChecked = previousChecked.filter(id => id !== e.target.value)
        this.checkedItems = this.checkedItems.filter(id => id !== e.target.value)
      }
      localStorage.setItem('saleCheckboxArray', JSON.stringify(Array.from(new Set(this.checkedItems.concat(previousChecked)))))
    },
    toggleSelectAllCheckboxes() {
      this.isAllSelected = !this.isAllSelected

      if (!this.isAllSelected) {
        this.checkedItems = []
      } else {
        this.checkedItems = this.items.map(item => item.id)
      }

      localStorage.setItem('saleCheckboxArray', JSON.stringify(this.isAllSelected ? 'all' : []))

      this.$emit('all-selected', {
        id: JSON.parse(localStorage.getItem('saleCheckboxArray')),
      })
    },
    toggleShowSelected() {
      this.showSelectedOnly = !this.showSelectedOnly

      if (!this.showSelectedOnly) {
        this.checkedItems = []
      }

      this.$emit('show-selected', {
        id: this.checkedItems
      })

    }
  },
}
</script>

<style lang="scss" scoped>
.illiquid-results {
  &__link {
    text-decoration: none;
    cursor: pointer;
    padding: 7px 16px 8px;
    border: 1px solid currentColor;
    border-radius: 56px;
    display: block;
    width: max-content;
    color: #0077c8;
    margin-bottom: 16px;
    margin-left: auto;
    font-size: 14px;
    font-weight: 600;
    transition: color 0.2s ease-in;

    &:hover {
      color: $token-colors-primary;
    }

    svg {
      display: inline-block;
      margin-bottom: -1px;
      margin-left: 2px;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    &-button {
      display: flex;
      align-items: center;
      gap: 8px;
      background: unset;
      border: unset;
    }
  }
}

.sale-page-card {
  padding: $token-spacers-s $token-spacers-2-xs;
  border-top: 1px solid $token-colors-black-20;

  @include breakpoint(v-tablet) {
    padding: $token-spacers-s 0;
  }

  &:last-child {
    border-bottom: 1px solid $token-colors-black-20;
  }

  &__wrapper {
    display: flex;
    gap: $token-spacers-xs;

    @include breakpoint(tablet) {
      flex-direction: column;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__text {
    display: block;
    color: $token-colors-black-60;
    width: 100%;
    max-width: 1040px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.2;
    font-family: "Roboto Mono", sans-serif;
    margin-bottom: $token-spacers-xs;

    @include breakpoint(mobile) {
      font-size: 16px;
    }
  }

  &__tech-state,
  &__tech-props,
  &__type,
  &__subtype {
    max-width: 50%;
  }

  &__code,
  &__brand,
  &__amount,
  &__amount-name,
  &__price,
  &__all-price {
    max-width: 16.66665%;

    @include breakpoint(tablet) {
      max-width: 50%;
    }
  }

  &__gost {
    max-width: calc(100% / 3);
  }

  &__checkbox {
    & input {
      display: none;

      &:checked {
        + .sale-page-card__checkbox-box {
          border-color: $token-colors-primary;

          .z-icon {
            display: block;

            path {
              fill: $token-colors-primary !important;
            }
          }
        }
      }
    }

    &-box {
      display: block;
      width: 24px;
      height: 24px;
      background-color: $token-colors-white;
      border: 1px solid $token-colors-black-30;
      border-radius: 4px;
      transition: all 0.2s ease-in;
      flex-shrink: 0;
      cursor: pointer;

      .z-icon {
        display: none;
      }
    }
  }
}

</style>