var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "illiquid-page" },
    [
      _c("Contacts", {
        staticClass: "u-bottom-margin--3xl",
        attrs: { data: _vm.contacts }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "illiquid-page__notifications" },
        [
          _c(
            "card-link",
            {
              staticClass: "u-bottom-margin--xl",
              attrs: {
                link: "https://www.nornickel.ru/suppliers/warning/",
                target: "_blank",
                theme: "warning",
                image: "/images/spy.svg"
              }
            },
            [
              _vm._v(
                "\n      Предупреждение о подложных обращениях от лица компании\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "card-link",
            {
              staticClass: "u-bottom-margin--xl",
              attrs: {
                theme: "warning",
                image: "/images/pr.svg",
                exclamation: false
              }
            },
            [
              _vm._v(
                "\n      Для обратной связи по улучшению сервиса реализации неликвидных МТР просим писать на "
              ),
              _c("span", { staticClass: "u-no-wrap" }, [_vm._v("e-mail")]),
              _vm._v(":\n      "),
              _c("a", { attrs: { href: "mailto:nelikvidi@kolagmk.ru" } }, [
                _vm._v("nelikvidi@kolagmk.ru")
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("IlliquidList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-107368a6", { render: render, staticRenderFns: staticRenderFns })
  }
}