<template>
    <component
        :is="link ? 'a' : 'div'"
        :href="link"
        class="card-link"
        :class="`card-link--${theme}`"
    >
        <div class="card-link__wrapper">
            <svg v-if="exclamation" version="1.1" viewBox="0 0 33 33" class="card-link__icon z-icon z-fill" style="width: 40px; height: 40px;">
                <path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.066 16.083c0-8.202 6.65-14.85 14.85-14.85 8.203 0 14.851 6.648 14.851 14.85s-6.648 14.85-14.85 14.85-14.85-6.648-14.85-14.85zM16.916 0C8.035 0 .835 7.2.835 16.083s7.2 16.083 16.083 16.083c8.882 0 16.083-7.2 16.083-16.083C33 7.201 25.8 0 16.917 0zm-2.733 13.126a2.854 2.854 0 115.468 0l-1.176 3.917a1.627 1.627 0 01-3.116 0l-1.176-3.917zm2.734-2.442a1.621 1.621 0 00-1.553 2.087l1.175 3.918a.394.394 0 00.756 0l1.175-3.918a1.621 1.621 0 00-1.553-2.087zm0 9.775a.887.887 0 100 1.774.887.887 0 000-1.774zm-2.12.887a2.12 2.12 0 114.24 0 2.12 2.12 0 01-4.24 0z" fill="#0077C8">
                </path>
            </svg>
            <div class="card-link__content">
                <slot />
            </div>
        </div>
        <div
            class="card-link__image"
            :style="image ? `background-image: url(${image})` : ''"
        ></div>
    </component>
    </template>
    
<script>
export default {
    name: 'card-link',
    props: {
        link: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            default: 'default',
            validator: prop => ['warning', 'default'].includes(prop)
        },
        exclamation:{
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            lang: this.$root.lang
        }
    }
}
</script>

<style lang="scss">
$warning-hover-color: #DB830D;
$cardLink: '.card-link';

a#{$cardLink} {
    text-decoration: none;
    transition: box-shadow 0.2s ease-in, border-color 0.2s ease-in;

    &:hover {
        border-color: $token-colors-primary;

        #{$cardLink}__content {
            color: $token-colors-primary;
        }

        #{$cardLink}__icon {
            path {
                fill: $token-colors-primary;
            }
        }
    }

    &--warning {
        &:hover {
            border-color: $warning-hover-color;

            #{$cardLink}__content {
                color: $warning-hover-color;
            }

            #{$cardLink}__icon {
                path {
                    fill: $warning-hover-color;
                }
            }

            #{$cardLink}__image {
                background-color: $warning-hover-color;
            }
        }
    }
}

#{$cardLink} {
    display: flex;
    border-radius: 20px 20px 20px 0;
    border: 1px solid #0077c8;
    overflow: hidden;

    &__content {
        @include typo-level(XL);
        color: #0077c8;
        transition: color 0.2s ease-in;

        @include breakpoint(v-tablet) {
            @include typo-level(M);
        }

        @include breakpoint(mobile) {
            @include typo-level(XL);
        }
    }

    &__icon {
        @include margin-level(right, XS);

        path {
            transition: fill 0.2s ease-in;
        }

        @include breakpoint (mobile) {
            width: 24px !important;
            height: 24px !important;
        }
    }

    &__wrapper {
        @include padding-level(top, S);
        @include padding-level(bottom, S);
        @include padding-level(left, S);
        @include padding-level(right, S);
        display: flex;
        align-items: center;
        flex-grow: 1;

        @include breakpoint (mobile) {
            padding: 16px;
        }
    }

    &__image {
        border-top-left-radius: 80px;
        background-color: $token-colors-primary;
        width: 26%;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        max-width: 280px;

        @include breakpoint(v-tablet) {
            width: 35%;
        }

        @include breakpoint (mobile) {
            display: none;
        }
    }

    &__logo {
        width: 80%;
        height: 80%;
        max-width: 150px;
        max-height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--warning {
        border: 1px solid #ff9e00;

        .z-icon {
            path {
                fill: #f38b00;
            }
        }

        #{$cardLink}__content {
            color: #f38b00;
        }

        #{$cardLink}__image {
            transition: background-color 0.2s ease-in;
            background-color: #ff9e00;
            background-size: auto;
        }
    }
}
</style>
