// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../../../node_modules/css-loader/index.js!../../../../node_modules/vue-loader/lib/style-compiler/index.js?{\"optionsId\":\"0\",\"vue\":true,\"scoped\":false,\"sourceMap\":true}!../../../../node_modules/sass-loader/dist/cjs.js!../../../../node_modules/sass-resources-loader/lib/loader.js?{\"sourceMap\":true,\"resources\":[\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/colors/_colors.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/_vars.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/base/mixins/_a-mixins.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_colors.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_grids.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_spacers.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_spacers-mixin.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_typography.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_typography-mixin.scss\"]}!./index.scss");
if(content.__esModule) content = content.default;
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add the styles to the DOM
var add = require("!../../../../node_modules/vue-style-loader/lib/addStylesClient.js").default
var update = add("e72677ec", content, false, {});
// Hot Module Replacement
if(module.hot) {
 // When the styles change, update the <style> tags
 if(!content.locals) {
   module.hot.accept("!!../../../../node_modules/css-loader/index.js!../../../../node_modules/vue-loader/lib/style-compiler/index.js?{\"optionsId\":\"0\",\"vue\":true,\"scoped\":false,\"sourceMap\":true}!../../../../node_modules/sass-loader/dist/cjs.js!../../../../node_modules/sass-resources-loader/lib/loader.js?{\"sourceMap\":true,\"resources\":[\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/colors/_colors.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/_vars.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/base/mixins/_a-mixins.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_colors.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_grids.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_spacers.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_spacers-mixin.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_typography.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_typography-mixin.scss\"]}!./index.scss", function() {
     var newContent = require("!!../../../../node_modules/css-loader/index.js!../../../../node_modules/vue-loader/lib/style-compiler/index.js?{\"optionsId\":\"0\",\"vue\":true,\"scoped\":false,\"sourceMap\":true}!../../../../node_modules/sass-loader/dist/cjs.js!../../../../node_modules/sass-resources-loader/lib/loader.js?{\"sourceMap\":true,\"resources\":[\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/colors/_colors.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/_vars.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/base/mixins/_a-mixins.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_colors.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_grids.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_spacers.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_spacers-mixin.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_typography.scss\",\"/pub/www/kolagmknornik/releases/20240827085535Z/frontend/styles/vars/tokens/_typography-mixin.scss\"]}!./index.scss");
     if(newContent.__esModule) newContent = newContent.default;
     if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];
     update(newContent);
   });
 }
 // When the module is disposed, remove the <style> tags
 module.hot.dispose(function() { update(); });
}