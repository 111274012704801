<template>
  <div class="illiquid-page">
    <Contacts class="u-bottom-margin--3xl" :data="contacts"/>

    <div class="illiquid-page__notifications">
      <card-link
          class="u-bottom-margin--xl"
          link="https://www.nornickel.ru/suppliers/warning/"
          target="_blank"
          theme="warning"
          image="/images/spy.svg"
      >
        Предупреждение о&nbsp;подложных обращениях от&nbsp;лица компании
      </card-link>
      <card-link
          class="u-bottom-margin--xl"
          theme="warning"
          image="/images/pr.svg"
          :exclamation="false"
      >
        Для обратной связи по&nbsp;улучшению сервиса реализации неликвидных МТР просим писать на&nbsp;<span class="u-no-wrap">e-mail</span>:
        <a href="mailto:nelikvidi@kolagmk.ru">nelikvidi@kolagmk.ru</a>
      </card-link>
    </div>

    <IlliquidList/>
  </div>
</template>

<script>
import IlliquidList from './IlliquidList.vue'
import Contacts from '../Contacts/Contacts.vue'
import CardLink from '../../app/CardLink/CardLink.vue'

export default {
  name: 'illiquid-page',
  components: {
    IlliquidList,
    Contacts,
    CardLink
  },
  data() {
    return {
      contacts: this.$root?.app?.components?.['lk-partner']?.contacts
    }
  }
}
</script>

<style lang="scss">
.illiquid-page {
  &__notifications{
    display: flex;
    gap: 64px;

    @include breakpoint(v-tablet) {
      flex-direction: column;
      gap: 0;
    }

    & .card-link{
      flex: 1 1;

      &__icon{
        @include breakpoint(mobile){
          display: none;
        }
      }
    }
  }
}
</style>