<template>
  <div class="illiquid" v-if="data">
    <filter-illiquid
        :data="filter"
        @change="onFilterChange"
    />
    <div class="u-relative u-top-padding--s">
      <z-preloader class="illiquid__preloader-top" v-if="isLoading"/>

      <results
          v-if="items && items.length"
          :items="items"
          @all-selected="onAllSelected"
          @show-selected="onShowSelected"
      />
      <div v-else>
        <h4 class="u-bottom-margin--3xs" v-html="TEXT.notFound.title"/>
        <p v-html="TEXT.notFound.description"/>
      </div>

      <z-preloader v-if="isLoading"/>

      <z-pagination
          v-if="paginationData"
          class="u-top-margin--3xl-important"
          show-next
          show-prev
          :data="paginationData"
          @change-page="onPaginationChange"
      />
    </div>
  </div>
</template>

<script>
import {throttle} from 'throttle-debounce'
import FilterIlliquid from './components/Filter.vue'
import Results from './components/Results.vue'
import ZPagination from '../../app/ZPagination/ZPagination.vue'
import ZPreloader from '../../app/ZPreloader/ZPreloader.vue'
import {getData} from '../api/illiquid'

const qs = require('query-string')

const TEXT = {
  notFound: {
    title: 'К сожалению, ничего не найдено',
    description: 'Попробуйте изменить параметры фильтра и повторить поиск',
  }
}

export default {
  name: 'illiquid',
  components: {
    FilterIlliquid,
    Results,
    ZPagination,
    ZPreloader
  },
  data() {
    return {
      TEXT,
      isLoading: false,
      params: {
        type: null,
        subType: null,
        page: 1,
        id: 'all'
      },
      data: this.$root?.app?.components?.illiquid,
      items: this.$root?.app?.components?.illiquid?.items,
      filter: this.$root?.app?.components?.illiquid?.filter,
      nav: this.$root?.app?.components?.illiquid?.nav
    }
  },
  computed: {
    paginationData() {
      if (this.nav) {
        return ({
          currentPage: this.nav?.current,
          totalItems: this.nav?.count,
          totalPages: this.nav?.total,
          pageRange: 1
        })
      }

      return null
    }
  },
  methods: {
    onPaginationChange(data) {
      this.params.page = data.page
      this.send()
    },
    send: throttle(500, async function () {
      this.isLoading = true
      let queryParams = qs.stringify(this.params, {arrayFormat: 'bracket'})

      try {
        const response = await getData(queryParams)
        this.items = response.items
        this.nav = response.nav
      } catch (e) {
        console.log(e);
      }

      this.isLoading = false
    }),
    onFilterChange(data) {
      this.params = {...this.params, ...data, page: 1}
      this.send()
    },
    onAllSelected(payload) {
      this.params = {...this.params, ...payload}
    },
    onShowSelected(payload) {
      this.params.page = 1
      this.params.id = payload.id
      this.send()
    },
  },
}
</script>

<style lang="scss">
.illiquid {
  &__preloader-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>