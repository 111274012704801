var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "illiquid-page" },
    [
      _c(
        "div",
        { staticClass: "illiquid-page__contacts u-bottom-margin--3xl" },
        [
          _c("Contacts", { attrs: { data: _vm.contacts } }),
          _vm._v(" "),
          _vm._m(0)
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "u-bottom-margin--xs" },
        [
          _c(
            "card-link",
            {
              attrs: {
                "image-size": "50%",
                image: "/images/property.svg",
                exclamation: false
              }
            },
            [
              _c("p", [
                _vm._v(
                  "Информируем об открытии склада — магазина СопчаСнаб по адресу г. Мончегорск, пос. Малая Сопча"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c(
                  "a",
                  {
                    staticClass: "link link--external",
                    attrs: {
                      href: "https://yandex.com/maps/-/CDTSyWlv",
                      target: "_blank"
                    }
                  },
                  [_c("span", [_vm._v("Смотреть на карте")])]
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "illiquid-page__notifications u-bottom-margin--m" },
        [
          _c(
            "card-link",
            {
              staticClass: "u-bottom-margin--xs",
              attrs: {
                link: "https://www.nornickel.ru/suppliers/warning/",
                target: "_blank",
                theme: "warning",
                image: "/images/spy.svg"
              }
            },
            [
              _vm._v(
                "\n      Предупреждение о подложных обращениях от лица компании\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "card-link",
            {
              staticClass: "u-bottom-margin--xs",
              attrs: {
                theme: "warning",
                image: "/images/pr.svg",
                exclamation: false
              }
            },
            [
              _vm._v(
                "\n      Для обратной связи по улучшению сервиса реализации неликвидных МТР просим писать на "
              ),
              _c("span", { staticClass: "u-no-wrap" }, [_vm._v("e-mail")]),
              _vm._v(":\n      "),
              _c("a", { attrs: { href: "mailto:nelikvidi@kolagmk.ru" } }, [
                _vm._v("nelikvidi@kolagmk.ru")
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("IlliquidList")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "illiquid-page__qr" }, [
      _c("p", { staticClass: "u-bottom-margin--2xs" }, [
        _vm._v("QR код для оплаты")
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "illiquid-page__qr-image",
        attrs: { src: "/local/templates/nornik/img/qr-kolagmk.png", alt: "" }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-107368a6", { render: render, staticRenderFns: staticRenderFns })
  }
}